import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Home from './pages/Home';

function App() {
  // Check if user is logged in
  const isLoggedIn = localStorage.getItem('isLogin') === 'true';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('', {
          key: 'value',
        });
      } catch (err) {
      }
    };

    fetchData();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Signup />} />
        <Route path="/signup" element={<Signup />} />
        {/* <Route path="/" element={isLoggedIn ? <Home /> : <Login />} /> */}
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
