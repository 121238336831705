import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Grid, Link } from '@mui/material';
import Alert from '@mui/material/Alert';
import icon from "../assets/Images/icon.jpg"
import Logo from "../assets/Images/WildlifeIQ_Dashboard.png"
import SignupModal from '../components/Login/SignupModal';
import "../css/signup.css";

function Login() {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const [login, setLogin] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (formData.email === storedUser.email && formData.password === storedUser.password) {
            localStorage.setItem('isLogin', true);
            setLogin(true)
            window.location.href = '/';
        } else {
            setLogin(false)
            handleOpen()
        }
    };

    return (
        <div className='home-container'>
            <div className='app-header'>
            <img className='app-name' src={Logo} alt="Forestor Buddy"></img>
            <img className='app-logo' src="https://valiancesolutions.com/wp-content/themes/blankslate/assets/images/logo.png"></img>
            </div>
            {/* {
                logout ? <Alert severity="success">Logout Successful</Alert> : null
            } */}
            <div className='app-signup'>
                Welcome to WildlifeIQ !
            </div>
            {/* <Button onClick={handleLogout} variant="contained" color="error">Logout</Button> */}
        </div>
    );
}

export default Login;
